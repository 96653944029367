





















































































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartRingComponent, ReportChartLineComponent, ReportChartHistogramComponent } from '@/mixins/report-chart-base';
import EnviromentIndexService from './service/enviroment-index';
import { EnvAirQualityQueryModel } from './model/env-air-quality-model';
import { EnvTemperatureHumidityQueryModel } from './model/env-temperature-humidity-model';

@Component
export default class EnviromentIndexComponent extends Mixins(ReportChartRingComponent, ReportChartLineComponent, ReportChartHistogramComponent) {
    headerCardList: Array<any> = [
        { title: '设备总数', count: null, unit: '台', loading: true, icon: '/img/device.png' },
        { title: '在线设备数', count: null, unit: '台', loading: true, icon: '/img/device-online.png' },
        { title: '今天告警总数', count: null, unit: '条', loading: true, icon: '/img/alarm-total.png' },
        { title: '正在告警', count: null, unit: '条', loading: true, icon: '/img/alarm.png' },
        { title: '今天未处理告警', count: null, unit: '条', loading: true, icon: '/img/alarm-unhandle.png' }
    ];

    airQualityCountData: any = null;
    airQualityCountDataLoading: boolean = true;

    temperatureHumidityCountData: any = {};
    temperatureHumidityCountDataLoading: boolean = true;

    alarmLocationCountData: any = {};
    alarmLocationCountDataLoading: boolean = false;

    alarmStatusCountData: any = {};
    alarmStatusCountDataLoading: boolean = true;

    last7DayAlarmData: any = {};
    last7DayAlarmDataLoading: boolean = true;

    alarmGradeCountData: any = {};
    alarmGradeCountDataLoading: boolean = true;

    created() {
        this.initData();
    }

    initData() {
        EnviromentIndexService.getFacilityCount().then(res => {
            this.headerCardList[0].count = res.totalCount;
            this.headerCardList[1].count = res.onlineCount;
        }).finally(() => {
            this.headerCardList[0].loading = false;
            this.headerCardList[1].loading = false;
        });
        EnviromentIndexService.getAlarmCount().then(res => {
            this.headerCardList[2].count = res.totalCount;
            this.headerCardList[3].count = res.totalCount;
            this.headerCardList[4].count = res.unsolvedCount;
        }).finally(() => {
            this.headerCardList[2].loading = false;
            this.headerCardList[3].loading = false;
            this.headerCardList[4].loading = false;
        });

        EnviromentIndexService.getAirQualityCountData(new EnvAirQualityQueryModel()).then(res => {
            this.airQualityCountData = res;
        }).finally(() => this.airQualityCountDataLoading = false);

        EnviromentIndexService.getTemperatureHumidityCount(new EnvTemperatureHumidityQueryModel()).then(res => {
            this.temperatureHumidityCountData = res;
        }).finally(() => this.temperatureHumidityCountDataLoading = false);

        EnviromentIndexService.getAlarmLocationCount().then(res => {
            this.alarmLocationCountData = res;
        }).finally(() => this.alarmLocationCountDataLoading = false);

        EnviromentIndexService.getLast7DayAlarmCount().then(res => {
            this.last7DayAlarmData = res;
        }).finally(() => this.last7DayAlarmDataLoading = false);

        EnviromentIndexService.getAlarmStatusCount().then(res => {
            this.alarmStatusCountData = res;
        }).finally(() => this.alarmStatusCountDataLoading = false);

        EnviromentIndexService.getAlarmGradeCount().then(res => {
            this.alarmGradeCountData = res;
        }).finally(() => this.alarmGradeCountDataLoading = false);
    }
}
